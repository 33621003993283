<template>
  <div :class="`footer-nav ${headerClass}`">
    <div class="footer-nav-content" :class="{ prevent: $loading }">
      <nav class="footer-nav-buttons">
        <a class="footer-nav-buttons-button __m1a" @click="UserAdminMenu">
          <iconic name="mf_lock" />
        </a>

        <a class="footer-nav-buttons-button" :class="`${mkLink(`explore=`)}`" @click="setQuery({ explore: true })">
          <iconic name="search-solid" />
        </a>

        <a :class="`footer-nav-buttons-button --home ${isOnHome && 'router-link-exact-active'}`" @click="goToHome">
          <iconic name="house" />
        </a>

        <a
          @click="$user ? goFilter({}, $links['my-notifications']) : UserAdminMenu()"
          :class="`footer-nav-buttons-button ${mkLink($links['my-notifications'])}`"
        >
          <iconic name="bell" />
          <BubbleNumber v-if="notificationsUnread" :number="notificationsUnread" />
        </a>

        <a @click="$user ? setQuery({ messages: 'wall' }) : UserAdminMenu()" :class="`footer-nav-buttons-button ${mkLink(`messages=`)}`">
          <iconic name="comment" />
          <BubbleNumber v-if="messagesUnread" :number="messagesUnread" />
        </a>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    linkInclude: function(input) {
      return this.$global.linkInclude(this.$route, input);
    },
    goToHome: function() {
      this.gotToUp();
      this.$router.push({ path: "/myfans1a" }).catch(() => {});
    },
  },
  computed: {
    isOnHome: function() {
      let isHome = this.$route.path === "/myfans1a" || this.$route.path === "/";
      isHome = isHome && !this.$route.query.explore && !this.$route.query.messages;
      isHome = isHome && !this.$route.query.feed && !this.$route.query.view;
      return isHome;
    },
    messagesUnread: function() {
      return this.$store.state.counter.messagesUnread;
    },
    notificationsUnread: function() {
      return this.$store.state.counter.notificationsUnread;
    },
    headerClass: function() {
      return this.$store.state.headerClass;
    },
  },
};
</script>

<style lang="scss" scoped>
$numicons: 5;
$shadowsize: 40px;
.footer-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: $mobile_nav_height;
  z-index: 12;
  &-content {
    background: #fff;
    height: 100%;
    box-shadow: $dshadow;
    border-top-right-radius: $mpadding;
    border-top-left-radius: $mpadding;
    width: 100%;
  }
  &-buttons {
    height: 100%;
    display: grid;
    grid-template-columns: repeat($numicons, 1fr);
    user-select: none;
    &-button {
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .iconic {
        @include Flex(row, center, center);
        width: $shadowsize;
        height: $shadowsize;
        color: $sec_color;
        font-size: 24px;
        text-align: center;
        border-radius: 50%;
      }
      &.__m1a .iconic {
        color: $primary_color;
        font-size: 180%;
      }
      &:active {
        @include d-active;
      }
      &.--home {
        .iconic {
          width: $shadowsize + $mpadding/3;
          height: $shadowsize + $mpadding/3;
        }
      }
      &.router-link-exact-active {
        .iconic {
          background: $primary_color;
          color: #fff;
        }
      }
    }
  }
  @media (min-width: $tablet_width) {
    display: none;
  }
}
</style>
