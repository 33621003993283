<template>
  <div class="search-autocomplete" v-if="searching">
    <div class="search-autocomplete-overlay" @click="$store.state.searching = false"></div>
    <div class="search-autocomplete-list">
      <div class="main">
        <div class="search-autocomplete-items">
          <SearchAutocompleteItem v-for="(creator, index) in $search['creators']" :key="index" :creator="creator" />
        </div>
        <Spacer num="2" />
      </div>
    </div>
  </div>
</template>

<script>
import SearchAutocompleteItem from "./SearchAutocompleteItem.vue";
export default {
  components: { SearchAutocompleteItem },
  data: function() {
    return {
      creators: false,
    };
  },
  methods: {
    search: function({ string }) {
      this.$store.state.loading = true;

      const callback = ({ creators }) => {
        this.$store.state.loading = false;
        this.creators = creators;
      };

      const error = (error) => {
        this.$store.state.loading = false;
        this.ialert.go({ title: "error", message: error });
      };

      this.$store.dispatch("searchCreator", { string, callback, error });
    },
  },
  computed: {
    searching: function() {
      return this.$store.state.searching;
    },
  },
};
</script>

<style lang="scss">
.search-autocomplete {
  @include Fixed();
  background: $body_bg;
  overflow-y: auto;
  &-overlay {
    @include Fixed();
  }
  &-list {
    position: relative;
    overflow-y: auto;
    padding: $header_height 0 $header_height 0;
  }
  &-items {
    padding: $mpadding/2;
  }
}
</style>
