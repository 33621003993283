const Landing = { methods: {}, computed: {}, watch: {} };

export default Landing;

Landing.watch["$screen.width"] = function(oldVal, newVal) {
  this.$store.state.appKey++;
};

Landing.watch["$visible"] = function(oldVal, newVal) {
  //
};

Landing.computed.socialLinks = function() {
  return [
    { name: "Instagram", link: `https://www.instagram.com/${Go.normalize(this.$locale["app_name"])}` },
    { name: "Twitter", link: `https://twitter.com/${Go.normalize(this.$locale["app_name"])}` },
    { name: "TikTok", link: `https://tiktok.com/@${Go.normalize(this.$locale["app_name"])}` },
    { name: "Facebook", link: `https://facebook.com/${Go.normalize(this.$locale["app_name"])}` },
  ];
};
