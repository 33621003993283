<template>
  <footer class="footer" v-if="$locale">
    <div class="footer-content">
      <LanguageSelectorBar class="footer-language" v-if="$screen.width <= 590" />
      <div class="footer-main main-tv">
        <a class="footer-info" @click="navigateToHome" cursor="pointer" active="button" href="#">
          <!-- <p bold>{{ $locale["object"]["footer"]["company"] }}</p>
          <p>{{ $locale["object"]["footer"]["address"] }}</p> -->
          <iconic class="mf_logo" name="mf_logo_shadow" :options="{ original: true }" />
        </a>
        <div class="footer-right">
          <ul class="footer-nav">
            <li class="nav-item" v-for="(social, index) in socialLinks" :key="index">
              <a target="_blank" :href="social.link" style="gap: calc(var(--mpadding) / 2)" flex-center>
                <iconic :name="Go.lower(social.name)" />
                <span>{{ social.name }}</span>
              </a>
            </li>
            <li class="nav-item">
              <router-link to="/?feed=gallery">{{ $locale["gallery_label"] }}</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/faq" upper>{{ $locale["object"]["footer"]["faq-btn"] }}</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/support">{{ $locale["object"]["footer"]["support-btn"] }}</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/terms">{{ $locale["object"]["footer"]["terms-btn"] }}</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/privacy">{{ $locale["object"]["footer"]["privacy-btn"] }}</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/dmca">DMCA</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/usc2257">18 U.S.C. § 2257 Statement</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/support/billing">{{ $locale["billing"] }}</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/content-removal">{{ $locale["content_removal_label"] }}</router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="footer-message" tcenter>
        <div class="footer-message-content main-tv">
          <small v-html="`${$locale['footer_message']}`"></small>
        </div>
      </div>
      <LandingFooterLegal _for="login" />
    </div>
  </footer>
</template>

<script>
  import landing from "./landing";
  export default {
    mixins: [landing],
    components: {
      LandingFooterLegal: () => import("./LandingFooterLegal.vue"),
      LanguageButton: () => import("../widgets/LanguageButton.vue"),
      LanguageSelectorBar: () => import("../widgets/LanguageSelectorBar.vue"),
    },
  };
</script>

<style lang="scss">
  .footer {
    &-content {
      background-color: #fff;
      position: relative;
      padding: $mpadding $mpadding * 1 $mpadding * 2 $mpadding * 1;
    }
    &-main {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
    }
    &-right {
      width: 100%;
    }
    &-nav {
      display: flex;
      margin: $mpadding 0 0 0;
      align-items: center;
      gap: $mpadding;
      flex-wrap: wrap;
      justify-content: center;
      .nav-item {
        white-space: nowrap;
        a {
          &:hover {
            color: $primary-color;
          }
        }
      }
    }
    &-message {
      margin: $mpadding 0 0 0;
      &-content {
        border-top: solid 1px $alto;
        padding: $mpadding 0 0 0;
      }
    }
    &-info {
      img,
      svg {
        max-height: $header_height;
      }
    }

    &-language {
      margin: 0 0 $mpadding 0;
      border-bottom: solid 1px $alto;
      padding: 0 0 $mpadding/1.5 0;
    }

    @include screen(590px) {
      &-content {
        padding: $mpadding * 2;
      }
      &-nav {
        gap: $mpadding;
      }
    }

    @include screen($tablet_width) {
      &-main {
        flex-direction: initial;
      }
      &-nav {
        justify-content: flex-end;
        margin: 0 0 0 0;
      }
    }
  }
</style>
