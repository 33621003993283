<template>
  <div :class="`search-header ${searching ? 'searching' : ''}`">
    <div class="search-header-content">
      <button><iconic name="search" /></button>
      <input type="text" name="search" placeholder="Buscar" autocomplete="off" @keyup="onSearch" @focus="searchEnter" v-model="lastWord" />
    </div>
  </div>
</template>

<script>
let timeout;
export default {
  data: function() {
    return {
      lastWord: "",
    };
  },
  methods: {
    searchLeave: function() {
      this.$store.state.searching = false;
    },
    searchEnter: function() {
      this.$store.state.searching = true;
    },
    onSearch: function({ target }) {
      !this.$loading && (this.$store.state.loading = true);
      clearTimeout(timeout);
      this.$store.state.search.word = target.value;
      timeout = setTimeout(() => {
        this.$emit("onSearch", { string: target.value });
      }, 500);
    },
  },
  computed: {
    search: function() {
      return this.$store.state.search;
    },
    searching: function() {
      return this.$store.state.searching;
    },
  },
  mounted: function() {
    this.lastWord = this.search.word;
  },
};
</script>

<style lang="scss" scoped>
$avatar_size: 42px;
.search-header {
  display: none;
  &-content {
    position: relative;
    width: 100%;
    border-radius: $mpadding/2;
    z-index: 1;
    input {
      padding: $mpadding/1.5 $mpadding * 2.5 $mpadding/1.5 $mpadding;
      font-size: 100%;
      font-family: $primary_font;
      width: 100%;
      border: none;
      background: none;
      border-radius: $mpadding/2;
    }
    button {
      position: absolute;
      padding: $mpadding/2 $mpadding/1.5 $mpadding/2 $mpadding/2;
      right: 0;
      background: none;
      border-radius: 0 2pc 2pc 0;
      border: none;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;
      font-size: 110%;
      color: $sec_color;
      .iconic {
        @include Flex(inherit, center, center);
      }
    }
  }
}
</style>
