<template>
  <a class="go-up" @click="gotToUp" v-if="$store.state.body.scrollTop > 720 && $screen.width > $screen.tablet.start">
    <iconic name="chevronUp" />
  </a>
</template>

<script>
export default {
  methods: {
    gotToUp: function() {
      this.$global.scrollToEl({ el: ".main-feed", topgap: 60 });
    },
  },
};
</script>

<style lang="scss">
.go-up {
  @include Flex(inherit, center, center);
  position: fixed;
  bottom: $mpadding * 1.5;
  right: $mpadding * 1.5;
  background: $sec_gradient;
  width: $mpadding * 3;
  height: $mpadding * 3;
  border-radius: 50%;
  box-shadow: $dshadow;
  cursor: pointer;
  .iconic {
    color: #fff;
  }
  * {
    pointer-events: none;
  }
  @include screen_tablet() {
  }
}
</style>
