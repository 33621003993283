<template>
  <div class="home-feed-list">
    <HomeFeedListCreator v-if="creator" />
    <HomeFeedList :post="post" v-else />
  </div>
</template>

<script>
export default {
  components: {
    HomeFeedList: () => import("./HomeFeedList.vue"),
    HomeFeedListCreator: () => import("./HomeFeedListCreator.vue"),
  },
  props: ["creator", "post"],
  metaInfo: function() {
    return {
      title: `My Fans 1A ${this.creator?.name ? `- ${this.creator.name}` : ""}`,
    };
  },
  computed: {
    explore: function() {
      return this.$route.query.explore;
    },
  },
};
</script>

<style lang="scss">
.home-feed {
  padding-bottom: $mobile_nav_height;
  .feed-list {
    .creator-post {
      display: inline-block;
      width: 100%;
      margin: $mpadding/2 0;
    }
  }
  @media (min-width: $tablet_width) {
    padding-bottom: 0;
    .feed-list {
      max-width: $pocket_width;
    }
  }
}
</style>
