<template>
  <div class="search-home">
    <div class="SearchHome">
      <TopCreatorsList />
    </div>
  </div>
</template>

<script>
export default {
  components: {
    TopCreatorsList: () => import("../creator/TopCreatorsList"),
  },
};
</script>

<style lang="scss">
.search-home {
  position: relative;
}
</style>
