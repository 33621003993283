var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"main-feed"},[(_vm.creator && !_vm.isAdminCreator())?_c('CreatorProfileCover',{attrs:{"creator":_vm.creator}}):_vm._e(),_c('div',{staticClass:"main-feed-content"},[_c('HomeFeedAside'),_c('div',{staticClass:"feed-wrap"},[_c('div',{class:("feed-list feed-column __" + (_vm.$route.query.feed_mode))},[(!_vm.creator)?_c('NotContent'):_c('div',{staticClass:"feedPostsContent"},[(_vm.groups)?_c('div',[_c('DynamicScroller',{staticClass:"scroller",attrs:{"items":_vm.groups,"min-item-size":220,"key-field":"_id","page-mode":"","buffer":1080},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
var index = ref.index;
var active = ref.active;
return [_c('DynamicScrollerItem',{attrs:{"item":item,"active":active,"size-dependencies":[item.message],"data-index":index}},[_c('FeedPost',{key:item._id,attrs:{"post":item,"index":index,"active":active}})],1)]}}],null,false,2215184989)})],1):(_vm.posts)?_c('div',[_c('DynamicScroller',{staticClass:"scroller",attrs:{"items":_vm.posts,"min-item-size":220,"key-field":"_id","page-mode":"","buffer":1080},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
var index = ref.index;
var active = ref.active;
return [_c('DynamicScrollerItem',{attrs:{"item":item,"active":active,"size-dependencies":[item.message],"data-index":index}},[_c('FeedPost',{key:item._id,attrs:{"post":item,"index":index,"active":active}})],1)]}}])})],1):_vm._e(),_c('LoadMore',{attrs:{"morepage":_vm.morepage},on:{"more":function($event){_vm.getPosts((_vm.cpage += 1))}}}),_c('Spacer',{attrs:{"num":"1"}})],1)],1)]),(_vm.$screen.width > 1040)?_c('HomeFeedCreators'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }