<template>
  <div class="result-item" v-if="creator">
    <router-link :to="`/${creator.user}#`" flex w100>
      <div :class="`result-item-content`" w100>
        <div class="wrap-picture">
          <figure class="picture" :style="`--avatar:url(${creator.picture});`"></figure>
          <!-- <figure class="flag" :style="`--flag:url(${creator.country_flag});`"></figure> -->
        </div>
        <div class="info">
          <p class="name">{{ creator.name }}</p>
          <p class="user">@{{ creator.user }}</p>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: ["creator"],
};
</script>

<style lang="scss">
$avatar_size: 42px;
.result-item {
  background-color: #fff;
  border-radius: $mradius;
  box-shadow: 2px 2px 6px rgba(68, 68, 68, 0.3);
  &:not(:last-child) {
    margin-bottom: $mpadding/2;
  }
  &-content {
    display: flex;
    align-items: center;
    padding: $mpadding/2 $mpadding;
    &:not(:last-child) {
      margin: 0 0 $mpadding/2 0;
    }
    &:hover {
      background-color: #e9e9e9;
    }
    .wrap-picture {
      position: relative;
      .picture {
        @include BgImage();
        width: $avatar_size;
        height: $avatar_size;
        background-image: var(--avatar);
        border-radius: 50%;
      }
      .flag {
        position: absolute;
        width: 20px;
        height: 20px;
        right: -$mpadding/1.5;
        bottom: 0;
        background-image: var(--flag);
        border-radius: 50%;
        background-size: cover;
      }
    }
    .info {
      margin: 0 0 0 $mpadding/1.5;
      .name {
        font-weight: bold;
      }
      .user {
        opacity: 0.7;
      }
    }
  }
}
</style>
