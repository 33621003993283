<template>
  <main class="main-feed">
    <div class="main-feed-content">
      <HomeFeedAside v-if="$screen.width > 1040" />
      <div class="feed-wrap">
        <div class="feed-list feed-column">
          <component v-if="hasFeedComponent" :is="getFeedComponent"></component>
          <Notifications v-else-if="$route.query.feed === 'notifications'" />
          <UserAdminFiles v-else-if="$route.query.feed === 'files'" />
          <UserSubscribers v-else-if="$route.query.feed === 'my_subscribers'" />
          <AdminFeed v-else-if="$route.query.admin" />
          <div class="feedPostsContent" v-else-if="posts">
            <DynamicScroller class="scroller" :items="posts" :min-item-size="220" key-field="_id" page-mode :buffer="1080">
              <template v-slot="{ item, index, active }">
                <DynamicScrollerItem :item="item" :active="active" :size-dependencies="[item.message]" :data-index="index">
                  <FeedPost :post="item" :index="index" :active="active" :key="item._id" />
                </DynamicScrollerItem>
              </template>
            </DynamicScroller>
            <LoadMore :morepage="morepage" @more="getPosts((cpage += 1))" />
            <Spacer num="1" />
          </div>
        </div>
      </div>
      <HomeFeedCreators v-if="$screen.width > 1040" />
    </div>
  </main>
</template>

<script>
import feed from "../feed/feed.js";
import HomeFeed from "../feed/HomeFeed.js";
export default {
  mixins: [feed, HomeFeed],
  components: {
    HomeFeedAside: () => import("./HomeFeedAside.vue"),
    LoadMore: () => import("../widgets/LoadMore.vue"),
    CreatorMenu: () => import("../creator/CreatorMenu.vue"),
    Notifications: () => import("../notifications/Notifications.vue"),
    AdminFeed: () => import("../admin/AdminFeed.vue"),
    UserAdminFiles: () => import("../user/UserAdminFiles.vue"),
    FeedPost: () => import("../feed/FeedPost.vue"),
    UserSubscribers: () => import("../user/UserSubscribers.vue"),
    HomeFeedCreators: () => import("./HomeFeedCreators.vue"),
  },
  props: ["post"],
  methods: {
    isLoading: function(state) {
      this.$store.commit("loading", state);
    },
    postReviewed: function({ post, status }) {
      this.$store.state.posts.posts = this.posts.map((item) => {
        if (item?._id === post?._id) {
          item.adminStatus = status;
        }
        return item;
      });
    },
  },
  mounted: async function() {
    this.getPosts();
  },
  watch: {
    "$route.params.post": function(oldVal, newVal) {
      this.getPosts();
    },
    "$store.state.posts.postReview": function(oldVal, newVal) {
      if (!newVal) return;
      const { reviewSuccess, post } = newVal;
      if (reviewSuccess) {
        this.postReviewed(newVal);
      }
    },
  },
};
</script>
