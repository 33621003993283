<template>
  <div class="feed-wrap">
    <div class="feed-list feed-column">
      <div class="feed-list-block">
        <AdminStatisticsGeneral v-if="$route.query.admin === 'statistics-home'" />
        <AdminStatisticsSales v-if="$route.query.admin === 'statistics-sales'" />
        <AdminStatisticsUsers v-if="$route.query.admin === 'statistics-users'" />
        <AdminStatisticsUsersCreators v-if="$route.query.admin === 'statistics-creators'" />
        <AdminStatisticsUsersFans v-if="$route.query.admin === 'statistics-fans'" />
        <AdminStatisticsUsersConnections v-if="$route.query.admin === 'statistics-connections'" />
        <AdminStatisticsSalesBags v-if="$route.query.admin === 'statistics-bags'" />
        <AdminComplaints v-if="$route.query.admin === 'complaints-home'" />
        <AdminVerifications v-if="$route.query.admin === 'verifications-home'" />
        <AdminProfile v-if="$route.query.admin === 'documents-home'" />
        <Notifications v-if="$route.query.view === 'notifications'" />
        <AdminCreatorVerifications v-if="$route.query.admin === 'creators-home'" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    AdminStatisticsGeneral: () => import("./AdminStatisticsGeneral.vue"),
    AdminStatisticsSales: () => import("./AdminStatisticsSales.vue"),
    AdminStatisticsUsers: () => import("./AdminStatisticsUsers.vue"),
    AdminStatisticsUsersCreators: () => import("./AdminStatisticsUsersCreators.vue"),
    AdminStatisticsUsersFans: () => import("./AdminStatisticsUsersFans.vue"),
    AdminStatisticsUsersConnections: () => import("./AdminStatisticsUsersConnections.vue"),
    AdminStatisticsSalesBags: () => import("./AdminStatisticsSalesBags.vue"),
    AdminComplaints: () => import("./AdminComplaints.vue"),
    AdminVerifications: () => import("./AdminVerifications.vue"),
    AdminCreatorVerifications: () => import("./AdminCreatorVerifications.vue"),
    AdminProfile: () => import("./AdminProfile.vue"),
    Notifications: () => import("../notifications/Notifications.vue"),
  },
};
</script>

<style lang="scss">
.admin-feed {
  position: relative;
}
</style>
