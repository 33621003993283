<template>
  <main class="main-feed">
    <CreatorProfileCover :creator="creator" v-if="creator && !isAdminCreator()" />
    <div class="main-feed-content">
      <HomeFeedAside />
      <div class="feed-wrap">
        <div :class="`feed-list feed-column __${$route.query.feed_mode}`">
          <NotContent v-if="!creator" />
          <div class="feedPostsContent" v-else>
            <div v-if="groups">
              <DynamicScroller class="scroller" :items="groups" :min-item-size="220" key-field="_id" page-mode :buffer="1080">
                <template v-slot="{ item, index, active }">
                  <DynamicScrollerItem :item="item" :active="active" :size-dependencies="[item.message]" :data-index="index">
                    <FeedPost :post="item" :index="index" :active="active" :key="item._id" />
                  </DynamicScrollerItem>
                </template>
              </DynamicScroller>
            </div>
            <div v-else-if="posts">
              <DynamicScroller class="scroller" :items="posts" :min-item-size="220" key-field="_id" page-mode :buffer="1080">
                <template v-slot="{ item, index, active }">
                  <DynamicScrollerItem :item="item" :active="active" :size-dependencies="[item.message]" :data-index="index">
                    <FeedPost :post="item" :index="index" :active="active" :key="item._id" />
                  </DynamicScrollerItem>
                </template>
              </DynamicScroller>
            </div>
            <LoadMore :morepage="morepage" @more="getPosts((cpage += 1))" />
            <Spacer num="1" />
          </div>
        </div>
      </div>
      <HomeFeedCreators v-if="$screen.width > 1040" />
    </div>
  </main>
</template>

<script>
import feed from "../feed/feed.js";
import HomeFeed from "../feed/HomeFeed.js";
export default {
  mixins: [feed, HomeFeed],
  components: {
    CreatorPostCard: () => import("../creator/CreatorPostCard.vue"),
    CreatorProfileCover: () => import("../creator/CreatorProfileCover.vue"),
    NotContent: () => import("../../views/404.vue"),
    LoadMore: () => import("../widgets/LoadMore.vue"),
    CreatorMenu: () => import("../creator/CreatorMenu.vue"),
    HomeFeedAside: () => import("./HomeFeedAside.vue"),
    HomeFeedCreators: () => import("./HomeFeedCreators.vue"),
  },
  methods: {
    isLoading: function(state) {
      this.$store.commit("loading", state);
    },
    postReviewed: function({ post, status }) {
      this.posts = this.posts.map((item) => {
        if (item._id === post._id) {
          item.adminStatus = status;
        }
        return item;
      });
    },
  },
  mounted: async function() {
    this.getPosts();
  },
  watch: {
    $route(to, from) {
      if (this.mustBeUpdate(to, from)) {
        this.getPosts();
      }
    },
    "$store.state.posts.postReview": function(oldVal, newVal) {
      if (!newVal) return;
      const { reviewSuccess, post } = newVal;
      if (reviewSuccess) {
        this.postReviewed(newVal);
      }
    },
  },
};
</script>
