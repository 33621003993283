<template>
  <section class="home-feed">
    <Header />
    <HomeFeedController :creator="creator" :post="post" />
    <router-view />
    <LandingFooter />
    <MobileFooter />
    <Search v-if="explore && $screen.width < 768" />
    <GoUpBtn />
  </section>
</template>

<script>
import Header from "./Header.vue";
import MobileFooter from "./MobileFooter.vue";
import HomeFeedList from "./HomeFeedList.vue";
import HomeFeedListCreator from "./HomeFeedListCreator.vue";
import Search from "../search/Search.vue";
import AdminFeed from "../admin/AdminFeed.vue";
import HomeFeedController from "./HomeFeedController.vue";
import GoUpBtn from "../widgets/GoUpBtn.vue";
import LandingFooter from "../landing/LandingFooter.vue";

export default {
  props: ["creator", "post"],
  components: {
    Header,
    HomeFeedList,
    HomeFeedListCreator,
    MobileFooter,
    Search,
    AdminFeed,
    HomeFeedController,
    GoUpBtn,
    LandingFooter,
  },
  metaInfo: function() {
    return {
      title: `My Fans 1A ${this.creator?.name ? `- ${this.creator.name}` : ""}`,
    };
  },
  computed: {
    explore: function() {
      return this.$route.query.explore;
    },
  },
};
</script>

<style lang="scss">
.home-feed {
  padding-bottom: $mobile_nav_height;
  .feed-list {
    .creator-post {
      display: inline-block;
      width: 100%;
      margin: $mpadding/2 0;
    }
  }
  @media (min-width: $tablet_width) {
    padding-bottom: 0;
    .feed-list {
      max-width: $pocket_width;
    }
  }
}
</style>
