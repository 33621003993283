export default {
  data: function() {
    return {
      groups: null,
      creator: "init",
      items: 12,
      cpage: 1,
      morepage: false,
    };
  },
  computed: {
    posts: function() {
      return this.$store.state.posts.posts;
    },
  },
  methods: {
    getPostsQuery: function({ page }) {
      let myRoute = this.$route.fullPath.split("?");
      let myQuery = `${myRoute[1] ? myRoute[1] : ""}`;
      this.cpage = page >= 1 ? page : 1;
      myQuery += `&page=${this.cpage}`;
      myQuery += `${this.$creator ? `&creator=${this.$creator}` : ""}`;
      myQuery += `${this.$route.params.post ? `&post=${this.$route.params.post}` : ""}`;
      return myQuery;
    },
    getPosts: async function(page) {
      this.isLoading(true);

      const response = await this.$store.dispatch("getPosts", { query: this.getPostsQuery({ page }) });

      this.creator = response.data.profile;
      this.$store.state.creatorFeed = this.creator;

      this.setPostsMode();

      if (response.data?.posts?.length >= this.items) {
        this.morepage = true;
      } else {
        this.morepage = false;
      }

      this.isLoading(false);
    },
    setPostsMode: function() {
      this.isGrid = this.$route.query.feed_mode === "grid";
      if (this.isGrid) {
        const groups = Go.arrayGroupOf(3, this.posts);
        this.groups = groups.map(this.postCreateGroup);
      } else {
        this.groups = null;
      }
    },
    postCreateGroup: function(group) {
      const groupId = group[0]._id;
      return { _id: groupId, group };
    },
    getPostCover: function(post) {
      if (!post) return null;

      if (!post.media) return null;

      let poster = "";

      post.media.find((m) => {
        if (m && m.type && m.type.includes("image")) {
          poster = m.src;
          return true;
        }

        if (m && m.type && m.type.includes("video")) {
          poster = this.getMediaPoster(m, post);
          return true;
        }
      });

      if (poster) {
        return this.encode_url(poster);
      }
    },
  },
};
