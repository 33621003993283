<template>
  <MyView ref="MyView" :meta="{}" :class="`search-view ${searching ? 'searching' : ''}`">
    <ViewHeader icon="arrowLeft" @back="goBack" class="search-view-header">
      <div slot="center" class="flex-center" w100>
        <SearchHeader @onSearch="$refs.SearchAutocomplete.search($event)" />
      </div>
    </ViewHeader>
    <ViewContent>
      <SearchHome />
      <SearchAutocomplete ref="SearchAutocomplete" />
    </ViewContent>
  </MyView>
</template>

<script>
import MyView from "../../views/View.vue";
import ViewHeader from "../view/ViewHeader.vue";
import ViewContent from "../view/ViewContent.vue";
import SearchHeader from "./SearchHeader.vue";
import SearchAutocomplete from "./SearchAutocomplete.vue";
import SearchHome from "./SearchHome.vue";
export default {
  components: { MyView, ViewHeader, ViewContent, SearchHeader, SearchAutocomplete, SearchHome },
  methods: {
    goBack: function() {
      if (this.searching) {
        this.$store.state.searching = null;
      } else {
        this.setQuery({ explore: undefined });
      }
    },
  },
  computed: {
    searching: function() {
      return this.$store.state.searching;
    },
  },
};
</script>

<style lang="scss">
.search-view {
  @include Fixed();
  &-header {
    .iconic {
      color: $primary-color;
    }
  }
  .search-header {
    display: block;
    width: 100%;
    margin: 0 $mpadding 0 0;
    &-content {
      background-color: #f0f2f5;
    }
    &.searching .search-header-result {
      @include Fixed();
      top: $header_height;
      background-color: $white_lilac;
      max-height: calc(100% - #{$header_height});
      overflow-y: auto;
      display: table;
      .content-list {
        padding: $mpadding $mpadding/2;
      }
      .content-list-item {
        background-color: #fff;
        margin: 0 $mpadding/2 $mpadding/2 $mpadding/2;
        border-radius: $mradius;
        box-shadow: 2px 2px 6px rgba(68, 68, 68, 0.3);
      }
    }
  }
}
</style>
